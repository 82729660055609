import Vue from 'vue';
import App from './App';
import { router } from './router';
// import { getTokens } from '@/libs/util'
import { getTokens } from '@/libs/util'
// import { getWxConfig } from '@/api/wxconfig'
import store from './store'

console.log(window.location);

import Vant from 'vant';
import 'amfe-flexible/index.js'
import 'vant/lib/index.css';
import { Toast } from 'vant';
import './assets/iconfont.css';
import './assets/iconfont_color.css'
import './index.less'
import LoadMore from 'vue-loadmore-simple'
Vue.use(LoadMore)
Vue.use(Vant);
Vue.use(Toast);

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  const token = getTokens()

  if (to.name != 'auth') {
    if (!token) {
      // Toast('没有token',token)
      sessionStorage.setItem('beforeUrl', to.fullPath);

      let appid = "wxeb21be17dff79da0";
      let redirect_uri = "https://webapp.charging.ebaina.com/auth";
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(redirect_uri)}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
    } else {
      // Toast('有token', token)

      next()
    }
  } else {
    next()
  }

  // next()

  if (to.meta.title) {
    document.title = to.meta.title
  }
})

new Vue({
  router,
  store,
  el: '#app',
  render: h => h(App)
});
