<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
// import { getData } from "@/api/user";

export default {
  created() {
    // this.$toast("welcome charging");
  },
};
</script>

<style>
body {
  background: #f7f7f7;
  -webkit-font-smoothing: antialiased;
}
</style>
