export default {
  // 配置显示在浏览器标签的title
  title: 'NUOLINK充电桩',
  // token在Cookie中存储的天数，默认1天
  cookieExpires: 36500,
  // api请求基础路径
  baseUrl: {
    dev: 'http://charging.dev.ebaina.com/',
    // dev: 'https://charging.ebaina.com',
    pro: 'https://charging.ebaina.com/',
  },
  // 默认打开的首页的路由name值，默认为home
  homeName: 'home'
}
