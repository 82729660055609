import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const routes = [
  {
    path: '*',
    redirect: '/home',
  },
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('./view/index/home'),
    meta: {
      title: 'NUOLINK充电桩'
    }
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import('./view/index/detail'),
    meta: {
      title: '设备详情'
    }
  },
  {
    path: '/fault',
    name: 'fault',
    component: () => import('./view/index/fault'),
    meta: {
      title: '故障反馈'
    }
  },
  {
    path: '/device',
    name: 'device',
    component: () => import('./view/index/device'),
    meta: {
      title: '正在充电'
    }
  },
  {
    path: '/power',
    name: 'power',
    component: () => import('./view/index/power'),
    meta: {
      title: '充电桩'
    }
  },
  {
    name: 'user',
    path: '/user',
    component: () => import('./view/user/user'),
    meta: {
      title: '我的'
    }
  },
  {
    path: '/power_recode',
    name: 'power_recode',
    component: () => import('./view/user/power_recode'),
    meta: {
      title: '充电记录'
    }
  },
  {
    path: '/recharge',
    name: 'recharge',
    component: () => import('./view/user/recharge'),
    meta: {
      title: '账户充值'
    }
  },
  {
    path: '/recharge_recode',
    name: 'recharge_recode',
    component: () => import('./view/user/recharge_recode'),
    meta: {
      title: '充值记录'
    }
  },
  {
    path: '/deal',
    name: 'deal',
    component: () => import('./view/user/deal'),
    meta: {
      title: '用户协议'
    }
  },
  {
    path: '/ic',
    name: 'ic',
    component: () => import('./view/user/ic'),
    meta: {
      title: '我的IC卡'
    }
  },
  {
    name: 'admin_login',
    path: '/admin_login',
    component: () => import('./view/admin/login.vue'),
    meta: {
      title: '登录'
    }
  },
  {
    name: 'admin_binding',
    path: '/admin_binding',
    component: () => import('./view/admin/binding.vue'),
    meta: {
      title: '扫码绑定'
    }
  },
  {
    name: 'admin_equipment_msg',
    path: '/admin_equipment_msg',
    component: () => import('./view/admin/equipment_msg.vue'),
    meta: {
      title: '设备信息'
    }
  },
  {
    name: 'admin_binding_list',
    path: '/admin_binding_list',
    component: () => import('./view/admin/binding_list.vue'),
    meta: {
      title: '更新绑定'
    }
  },
  {
    name: 'admin_binding_detail',
    path: '/admin_binding_detail',
    component: () => import('./view/admin/binding_detail.vue'),
    meta: {
      title: '更新绑定'
    }
  },
  {
    name: 'auth',
    path: '/auth',
    component: () => import('./view/auth.vue'),
  },
];

// add route path
routes.forEach(route => {
  route.path = route.path || '/' + (route.name || '');
});

const router = new Router({ routes, mode: 'history', });

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});

export {
  router
};
