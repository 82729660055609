import { setTokens, getTokens, getAdminTokens } from '@/libs/util'


export default {
  state: {
    ali_oss: 'https://ebaina.oss-cn-hangzhou.aliyuncs.com/',
    userInfo: {},
    token: getTokens(),
    admin_token: getAdminTokens(),
  },
  mutations: {
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo
    },
    setToken(state, token) {
      state.token = token
      setTokens(token)
    },
  },
  actions: {
    // 登录
    handleLogin({ commit }, info) {
      console.log('handleLogin------', info);
      commit('setUserInfo', info.user_info)
      commit('setToken', info.jwt_token)

      let vuex_info = this.state.app
      localStorage.setItem('charging_store_app', JSON.stringify(vuex_info))
    },
    // 退出登录
    // handleLogOut({ commit }) {
    //   commit('setToken', '')
    //   localStorage.removeItem('aiborui_store_user', '')
    //   window.location.href = '/'
    // },
  }
}
